<template>
  <div class="home">
    <section class="menu">
      <div class="content">
        <div class="logo">
          <router-link to="/">
            <img src="@/assets/logo-white.png" alt="Logo Rotterdam Speedboat">
          </router-link>
        </div>
        <div class="items">
          <a href="#over" class="item">Over ons</a>
          <a href="#faq" class="item">FAQ</a>
          <a href="#tarieven" class="item">Tarieven</a>
          <a href="#reserveren" class="item">Reserveren</a>
          <a href="#contact" class="item">Contact</a>
        </div>
      </div>
    </section>
    <section class="hero">
      <img src="@/assets/boat.png" alt="Speedboat" class="boat">
      <div class="content">
        <h1>Huur een échte speedboat!</h1>
        <h2>Stap op in de jachthaven in Rotterdam en beleef een heerlijke tijd op 't water</h2>
        <a href="#reserveren" class="cta">Direct reserveren</a>
      </div>
    </section>
    <section class="about" id="over">
      <div class="content">
        <div class="left">
          <img src="@/assets/sea-ray.png" alt="Sea Ray SPX 190">
        </div>
        <div class="right">
          <h3>Over Rotterdam Speedboat</h3>
          <span>Bij Rotterdam Speedboat huur je een echte speedboat voor één of meerdere dagen. Je reservering plaats je gemakkelijk en snel, en betalen doe je veilig via iDeal. Je reservering plaats dus al binnen enkele minuten. Onze Sea Ray SPX 190 kun je huren met of zonder vaarbewijs, lees hierover meer bij de veelgestelde vragen. Tot snel (op het water) bij Rotterdam Speedboat.</span>
        </div>
      </div>
    </section>
    <section class="rates" id="tarieven">
      <div class="content">
        <h3>Tarieven</h3>
        <span>Onze speedboat huur je al vanaf <b>€ 795,- per dag</b>. Daarnaast vragen wij een <b>borg van € 750,-</b> per reservering. Hieronder kan je onze beschikbare dagen vinden. Wil je voor meerdere dagen reserveren? Neem dan contact met ons op.</span>
      </div>
    </section>
    <section class="image">
      <div class="content">
        <h3>Sea Ray SPX 190 huren, ook zonder vaarbewijs!</h3>
        <a href="/sh" target="_blank">Download Spec Sheet</a>
      </div>
    </section>
    <section class="reservation" id="reserveren">
      <div class="content">
        <h3>Reserveren</h3>
        <v-date-picker
          mode='single'
          tint-color='#f142f4'
          v-model='selectedDate'
          :theme-styles='themeStyles'
          is-double-paned
          :min-date='new Date().setDate(new Date().getDate()+1)'
          :max-date='new Date().setDate(new Date().getDate()+180)'
          :disabled-dates="disabledDates"
          :columns="$screens({ default: 1, lg: 3 })"
          :model-config="modelConfig"
          is-expanded
          ref="calendar">
        </v-date-picker>
        <div class="information" v-if="selectedDate">
          <span class="title">Persoonlijke gegevens</span>
          <div class="input">
            <span>Voor- en achternaam *</span>
            <input type="text" v-model="Name">
          </div>
          <div class="input">
            <span>Straat en huisnummer *</span>
            <input type="text" v-model="Street">
          </div>
          <div class="input">
            <span>Postcode *</span>
            <input type="text" v-model="Postcode">
          </div>
          <div class="input">
            <span>Woonplaats *</span>
            <input type="text" v-model="City">
          </div>
          <div class="input">
            <span>Telefoonnummer *</span>
            <input type="text" v-model="Phone">
          </div>
          <div class="input">
            <span>E-mailadres *</span>
            <input type="text" v-model="Email">
          </div>
          <div class="input full">
            <label class="check">Ja, ik ben in het bezit van een geldig vaarbewijs (niet verplicht)
              <input type="checkbox" v-model="VB">
              <span class="checkmark"></span>
            </label>
          </div>
          <span class="title">Uploaden</span>
          <div class="files">
            <label for="fileID" class="file" @dragover="dragover" @dragleave="dragleave" @drop="drop($event, 'id')" :class="{ success : fileID.length > 0 }">
              <input type="file" name="fileID" id="fileID" ref="fileID" accept=".pdf,.jpg,.jpeg,.png" @change="uploaded('id', $refs.fileID.files)">
              <span v-if="fileID.length > 0"><svg class="success" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M21.03 5.72a.75.75 0 010 1.06l-11.5 11.5a.75.75 0 01-1.072-.012l-5.5-5.75a.75.75 0 111.084-1.036l4.97 5.195L19.97 5.72a.75.75 0 011.06 0z"/></svg><span>Identiteitsbewijs geüpload</span><span class="delete" @click="fileID = []">Verwijderen</span></span>
              <span v-else><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 512 512"> <g> <g> <path d="m158.7,136c11.8,10.6 24.8,3.3 28.9-1l48-51.6v297.2c0,11.3 9.1,20.4 20.4,20.4 11.3,0 20.4-9.1 20.4-20.4v-297.3l48,51.6c7.7,8.3 20.6,8.7 28.9,1 8.3-7.7 8.7-20.6 1-28.9l-83.4-89.6c-3.9-4.1-9.3-6.5-14.9-6.5-5.7,0-11.1,2.4-14.9,6.5l-83.4,89.6c-7.7,8.4-7.4,21.5 1,29z"/> <path d="M480.6,335c-11.3,0-20.4,9.1-20.4,20.4v104.8H51.8V355.4c0-11.3-9.1-20.4-20.4-20.4c-11.3,0-20.4,9.1-20.4,20.4v125.2 c0,11.3,9.1,20.4,20.4,20.4h449.2c11.3,0,20.4-9.1,20.4-20.4V355.4C501,344.1,491.9,335,480.6,335z"/> </g> </g></svg>Upload een foto van je identiteitsbewijs</span>
            </label>
            <label v-if="VB" for="fileLicense" class="file" @dragover="dragover" @dragleave="dragleave" @drop="drop($event, 'license')" :class="{ success : fileLicense.length > 0 }">
              <input type="file" name="fileLicense" id="fileLicense" ref="fileLicense" accept=".pdf,.jpg,.jpeg,.png" @change="uploaded('license', $refs.fileLicense.files)">
              <span v-if="fileLicense.length > 0"><svg class="success" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M21.03 5.72a.75.75 0 010 1.06l-11.5 11.5a.75.75 0 01-1.072-.012l-5.5-5.75a.75.75 0 111.084-1.036l4.97 5.195L19.97 5.72a.75.75 0 011.06 0z"/></svg><span>Vaarbewijs geüpload</span><span class="delete" @click="fileLicense = []">Verwijderen</span></span>
              <span v-else><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 512 512"> <g> <g> <path d="m158.7,136c11.8,10.6 24.8,3.3 28.9-1l48-51.6v297.2c0,11.3 9.1,20.4 20.4,20.4 11.3,0 20.4-9.1 20.4-20.4v-297.3l48,51.6c7.7,8.3 20.6,8.7 28.9,1 8.3-7.7 8.7-20.6 1-28.9l-83.4-89.6c-3.9-4.1-9.3-6.5-14.9-6.5-5.7,0-11.1,2.4-14.9,6.5l-83.4,89.6c-7.7,8.4-7.4,21.5 1,29z"/> <path d="M480.6,335c-11.3,0-20.4,9.1-20.4,20.4v104.8H51.8V355.4c0-11.3-9.1-20.4-20.4-20.4c-11.3,0-20.4,9.1-20.4,20.4v125.2 c0,11.3,9.1,20.4,20.4,20.4h449.2c11.3,0,20.4-9.1,20.4-20.4V355.4C501,344.1,491.9,335,480.6,335z"/> </g> </g></svg>Upload een foto van je vaarbewijs</span>
            </label>
          </div>
          <span class="title">Voorwaarden</span>
          <div class="input download">
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="Solid"><path d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z"/><path d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z"/></g></svg>
            <a href="/av.pdf" target="_blank">Download onze algemene Voorwaarden</a>
          </div>
          <div class="input full">
            <label class="check">Ja, ik heb de algemene voorwaarden begrepen, gelezen en ga hiermee akkoord *
              <input type="checkbox" v-model="AV">
              <span class="checkmark"></span>
            </label>
          </div>
          <span class="button" v-if="Name && Street && Postcode && City && Phone && validEmail(Email) && AV && checkFiles()" @click="createReservation">Reserveren en veilig betalen</span>
          <span class="button disabled" v-else>Reserveren en veilig betalen</span>
        </div>
      </div>
    </section>
    <section class="faq" id="faq">
      <div class="content">
        <h3>FAQ - Veelgestelde vragen over het huren</h3>
        <div class="item">
          <span class="title">Kan ik ook de speedboat huren zonder vaarbewijs?</span>
          <span class="description">Ja, het is mogelijk om onze speedboat te huren zonder in het bezit te zijn van een geldig vaarbewijs. Echter, wordt door onze medewerkers de speedboat dan wel begrensd tot 10pk en kun je niet harder dan 20km/h varen.</span>
        </div>
        <div class="item">
          <span class="title">Waar stappen we op?</span>
          <span class="description">De opstaplocatie voor de speedboat is Jachthaven Blijdorp in Rotterdam. De ophaaltijd is 09:00 uur en de inlevertijd is 17:00 uur.</span>
        </div>
        <div class="item">
          <span class="title">Mag ik de speedboat zelf ook vervoeren naar een andere locatie?</span>
          <span class="description">Het is helaas niet mogelijk om de speedboat zelf te vervoeren naar een andere locatie.</span>
        </div>
        <div class="item">
          <span class="title">Wat gebeurt er als ik schade maak aan de speedboat?</span>
          <span class="description">Bij het in ontvangst nemen na de huurperiode, nemen we samen de schade op. Bij noodgevallen op het water kun je ons bereiken op 010 420 1115.</span>
        </div>
        <div class="item">
          <span class="title">Kan ik voor meer dan één of twee dagen huren?</span>
          <span class="description">Wil je onze speedboat huren voor meerdere dagen? Neem dan even telefonisch contact met ons op via 010 420 1115.</span>
        </div>
        <div class="item">
          <span class="title">Mag ik anderen laten varen als ik de reservering plaats?</span>
          <span class="description">Nee, alleen de huurder op de overeenkomst mag het vaartuig besturen/bedienen. In het geval dat anderen de speedboat besturen, zijn zij niet verzekerd. </span>
        </div>
      </div>
    </section>
    <section class="footer" id="contact">
      <div class="content">
        <div class="left">
          <img src="@/assets/logo-white.png" alt="Logo Rotterdam Speedboat">
        </div>
        <div class="right">
          <b>Rotterdam Speedboat</b>
          <span>Strandweg 23</span>
          <span>2761 DM Zevenhuizen</span>
          <span>info@rotterdamspeedboat.nl</span>
          <span>010 420 1115</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Home',

  data() {
    return {
      Name: '',
      Street: '',
      Postcode: '',
      City: '',
      Phone: '',
      Email: '',
      fileID: [],
      fileLicense: [],
      VB: false,
      SR: false,
      AV: false,
      disabledDates: [],
      errors: [],
      selectedDate: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD'
      },
      themeStyles: {
        wrapper: {
          border: '1',
        },
        header: {
          color: '#fafafa',
          backgroundColor: '#f142f4',
          borderColor: '#404c59',
          borderWidth: '1px 1px 0 1px',
        },
        headerVerticalDivider: {
          borderLeft: '1px solid #404c59',
        },
        weekdays: {
          color: '#ffffff',
          backgroundColor: '#f142f4',
          borderColor: '#ff0098',
          borderWidth: '0 1px',
          padding: '5px 0 10px 0',
        },
        weekdaysVerticalDivider: {
          borderLeft: '1px solid #404c59',
        },
        weeks: {
          border: '1px solid #dadada',
        }
      }
    }
  },

  mounted() {
    axios.get(`https://www.lakeseven.nl/api/speedboat/check/`)
    .then(response => {
      this.disabledDates = response.data.date.map(string => new Date(string))
    })
    .catch(e => {
      this.errors.push(e)
    })
  },

  metaInfo() {
    return { 
        title: "Rotterdam Speedboat",
        meta: [
            { name: 'description', content:  'Bij Rotterdam Speedboat huur je een echte speedboat voor één of meerdere dagen' },
            { property: 'og:title', content: "Rotterdam Speedboat" },
            { property: 'og:site_name', content: 'rotterdamspeedboat' },
            { property: 'og:type', content: 'website' },
            { name: 'robots', content: 'index,follow' }
        ]
    }
  },

  methods: {
    createReservation: function () {
      this.$toast.open({
        type: 'default',
        message: 'Betaling opmaken...',
        duration: 60000
      });

      let formData = new FormData();
      formData.append('fileID', this.fileID[0]);
      formData.append('fileLicense', this.fileLicense[0]);
      formData.append('Date', this.selectedDate);
      formData.append('Name', this.Name);
      formData.append('Street', this.Street);
      formData.append('Postcode', this.Postcode);
      formData.append('City', this.City);
      formData.append('Phone', this.Phone);
      formData.append('Email', this.Email);
      formData.append('VB', this.VB);

      axios({
        method: 'post',
        url: 'https://www.lakeseven.nl/api/speedboat/create/',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
      }).then(function (response) {
        if(response.data.link) {
          setTimeout(function(){ window.location.replace(response.data.link) }, 1500);
        }
      })
    },

    checkFiles() {
      if(this.VB) {
        if(this.fileID.length > 0 && this.fileLicense.length > 0) {
          return true
        }
      } else {
        if(this.fileID.length > 0) {
          return true
        }
      }
    },
    
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('hover')) {
        event.currentTarget.classList.add('hover')
      }
    },

    dragleave(event) {
      event.currentTarget.classList.remove('hover')
    },

    drop(event, type) {
      event.preventDefault()
      event.currentTarget.classList.remove('hover')

      if(type == 'id') { this.$refs.fileID = event.dataTransfer.files, this.uploaded('id', event.dataTransfer.files) }
      if(type == 'license') { this.$refs.fileLicense = event.dataTransfer.files, this.uploaded('license', event.dataTransfer.files) }
    },

    uploaded(type, file) {
      if(type == 'id') { this.fileID = file }
      if(type == 'license') { this.fileLicense = file }
    }
  }
}
</script>

<style>
html {
  scroll-behavior: smooth !important;
}

.menu {
  background: #131313;
}

.menu .content {
  display: flex;
  max-width: 1200px;
  margin: auto;
  padding: 15px 0;
}

.menu .content .logo {
  width: 250px;
}

.menu .content .logo img {
  width: 100%;
}

.menu .content .items {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 250px);
}

.menu .content .items .item {
  display: flex;
  align-items: center;
  padding-left: 45px;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
}

.hero {
  position: relative;
  width: 100%;
  background: #005d70;
}

.hero .boat {
  position: absolute;
  right: 0;
  bottom: -115px;
  width: 35%;
  min-width: 600px;
}

.hero .content {
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  margin: auto;
  padding: 125px 0;
}

.hero .content h1 {
  width: 50%;
  font-size: 60px;
  color: #FFFFFF;
  letter-spacing: 1.86px;
  line-height: 54px;
}

.hero .content h2 {
  width: 50%;
  font-size: 26px;
  color: #FFFFFF;
  letter-spacing: 0.81px;
  line-height: 30px;
  font-weight: 300;
  margin-top: 25px;
}

.hero .content .cta {
  display: block;
  width: fit-content;
  margin-top: 25px;
  padding: 0 25px;
  background: #11424B;
  font-size: 15px;
  color: #FFFFFF;
  letter-spacing: 0.46px;
  text-align: center;
  line-height: 54px;
  text-decoration: none;
}

.hero .content .boat {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99;
  width: 50%;
}

.about .content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 75px auto;
}

.about .content .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(50% - 100px);
}

.about .content .left img {
  margin: 100px 0;
  width: 100%;
}

.about .content .right {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: calc(50% - 100px);
}

.about .content .right h3 {
  font-size: 43px;
  color: #131313;
  letter-spacing: 1.33px;
  line-height: 51px;
  margin-bottom: 25px;
}

.about .content .right span {
  font-size: 16px;
  color: #131313;
  letter-spacing: 0;
  line-height: 36px;
}

.rates .content {
  max-width: 1200px;
  margin: 75px auto;
}

.rates .content h3 {
  font-size: 43px;
  color: #131313;
  letter-spacing: 1.33px;
  margin-bottom: 25px;
}

.rates .content span {
  font-size: 16px;
  color: #131313;
  letter-spacing: 0;
  line-height: 36px;
}

.image {
  display: flex;
  align-items: center;
  background: linear-gradient(rgba(43, 62, 83, 0.4), rgba(43, 62, 83, 0.4)), url('~@/assets/image.jpg');
  background-position: 50%;
  background-size: cover;
  min-height: 500px;
}

.image .content {
  max-width: 1200px;
  margin: 75px auto;
}

.image .content h3 {
  width: 50%;
  font-size: 43px;
  color: #fff;
  letter-spacing: 1.33px;
  margin-bottom: 25px;
}

.image .content a {
  display: block;
  width: fit-content;
  text-decoration: none;
  padding: 20px 30px;
  margin-top: 15px;
  background: #005d70;
  color: #fff;
  cursor: pointer;
}

.faq .content {
  max-width: 1200px;
  margin: 75px auto;
}

.faq .content h3 {
  font-size: 43px;
  color: #131313;
  letter-spacing: 1.33px;
  margin-bottom: 25px;
}

.faq .content .item {
  margin-bottom: 25px;
}

.faq .content .item .title {
  display: block;
  font-size: 16px;
  color: #131313;
  letter-spacing: 0;
  line-height: 36px;
  font-weight: 500;
}

.faq .content .item .description {
  display: block;
  font-size: 16px;
  color: #131313;
  letter-spacing: 0;
  line-height: 36px;
  font-weight: 300;
  max-width: 60%;
}

.reservation {
  background: #005d7027;
  padding: 50px 0;
}

.reservation .content {
  max-width: 1200px;
  margin: 50px auto;
}

.reservation .content h3 {
  font-size: 43px;
  color: #131313;
  letter-spacing: 1.33px;
  margin-bottom: 25px;
}

.reservation .content .intro {
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
  color: #131313;
  letter-spacing: 0;
  line-height: 36px;
}

.reservation .content .information {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 50px;
}

.reservation .content .information .title {
  display: block;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  color: #131313;
  letter-spacing: 1.33px;
  margin: 25px 0;
}

.reservation .content .information .input {
  width: calc(50% - 25px);
  margin-bottom: 25px;
}

.reservation .content .information .input.full {
  width: 100%;
}

.reservation .content .information .input.download {
  display: flex;
}

.reservation .content .information .input.download a {
  margin: 6px 0 0 0;
}

.reservation .content .information .input span {
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}

.reservation .content .information .input input {
  padding: 15px;
  border: 0;
  border-radius: 2.5px;
  width: calc(100% - 32px);
}

.reservation .content .information .input svg {
  width: 25px;
  height: 25px;
  margin-right: 25px;
}

.reservation .content .information .button {
  display: block;
  padding: 20px 30px;
  margin-top: 15px;
  background: #005d70;
  color: #fff;
  cursor: pointer;
}

.reservation .content .information .button.disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.reservation .content .information .files {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.reservation .content .information .files .file {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  border: solid 1px #bbcdd1;
  border-radius: 5px;
  margin-right: 25px;
  padding: 0 50px;
  width: 250px;
  height: 175px;
  cursor: pointer;
}

.reservation .content .information .files .file span {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.reservation .content .information .files .file span svg {
  display: block;
  width: 50px;
  fill: #d8e6e9;
  margin-bottom: 15px;
}

.reservation .content .information .files .file span svg.success {
  fill: #333;
}

.reservation .content .information .files .file span span {
  width: 100%;
}

.reservation .content .information .files .file span span.delete {
  font-size: 12px;
  margin-top: 10px;
  text-decoration: underline;
}

.reservation .content .information .files .file input {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.reservation .content .information .files .file.hover, .reservation .content .information .files .file.success {
  background: lightgreen;
}

.footer {
  background: #131313;
}

.footer .content {
  display: flex;
  max-width: 1200px;
  margin: auto;
  padding: 100px 0;
}

.footer .content .left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  text-align: center;
}

.footer .content .left img {
  width: 50%;
}

.footer .content .right {
  width: 50%;
}

.footer .content .right b {
  display: block;
  font-size: 18px;
  color: #fff;
  line-height: 28px;
  font-weight: 500;
}

.footer .content .right span {
  display: block;
  font-size: 18px;
  color: #fff;
  line-height: 28px;
  font-weight: 300;
}

@media (max-width: 900px) {
  .hero .boat {
    display: none;
  }
}

@media (max-width: 640px) {
  .menu .content .logo {
    width: 100%;
    padding: 25px;
  }

  .menu .content .items {
    display: none;
  }

  .hero .content {
    padding: 50px;
  }

  .hero .content h1 {
    width: 100%;
    font-size: 40px;
    line-height: 44px;
  }

  .hero .content h2 {
    width: 100%;
  }

  .hero .content .boat {
    display: none;
  }

  .about .content {
    flex-wrap: wrap;
    margin-top: 0;
  }

  .about .content .left {
    width: 100%;
    justify-content: center;
  }

  .about .content .right {
    width: 100%;
    padding: 50px 50px 0 50px;
  }

  .rates .content {
    padding: 50px;
  }

  .image .content {
    padding: 50px;
  }

  .image .content h3 {
    width: 100%;
    font-size: 40px;
  }

  .reservation {
    padding: 0;
  }

  .reservation .content {
    padding: 50px;
  }

  .reservation .content .information .input {
    width: 100%;
  }

  .reservation .content .information .input svg {
    display: none;
  }

  .reservation .content .information .files .file {
    margin: 0 0 15px 0;
  }

  .faq .content {
    padding: 50px;
    margin: 0;
  }

  .faq .content h3 {
    font-size: 40px;
  }

  .faq .content .item .description {
    max-width: 100%;
  }

  .footer .content {
    flex-wrap: wrap;
  }

  .footer .content .left {
    width: 100%;
  }

  .footer .content .right {
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }
}


/* CALENDAR */
.vc-nav-popover-container {
  background-color: #ffffff !important;
}

.vc-day-content.is-disabled {
  background: #ff000008;
}


/* Checkbox */
.check {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-top: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
}

.check:hover input ~ .checkmark {
  background-color: #ccc;
}

.check input:checked ~ .checkmark {
  background-color: #8fbc8f;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check input:checked ~ .checkmark:after {
  display: block;
}

.check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


/* Toast */
.v-toast__item .v-toast__text {
  color: #fff !important;
}
</style>