<template>
    <div class="reservering">
        <section class="menu">
            <div class="content">
                <div class="logo">
                    <router-link to="/">
                        <img src="@/assets/logo-white.png" alt="Logo Sall Automobielen">
                    </router-link>
                </div>
                <div class="items"></div>
            </div>
        </section>
        <section class="rates" id="tarieven">
            <div class="content">
                <h3>Bedankt voor je reservering</h3>
                <span>Wij hebben een bevestiging naar je e-mail verstuurd. We zien je dan!</span>
            </div>
        </section>
        <section class="about" id="over">
            <div class="content">
                <div class="left">
                    <img src="@/assets/sea-ray.png" alt="Sea Ray SPX 190">
                </div>
                <div class="right">
                    <h3>Over Speedboat Rotterdam</h3>
                    <span>Bij Rotterdam Speedboat huur je een echte speedboat voor één of meerdere dagen. Je reservering plaats je gemakkelijk en snel, en betalen doe je veilig via iDeal. Je reservering plaats dus al binnen enkele minuten. Onze Sea Ray SPX 190 kun je huren met of zonder vaarbewijs, lees hierover meer bij de veelgestelde vragen. Tot snel (op het water) bij Rotterdam Speedboat.</span>
                </div>
            </div>
        </section>
        <section class="footer" id="contact">
            <div class="content">
                <div class="left">
                    <img src="@/assets/logo-white.png" alt="Logo wit Sall Automobielen">
                </div>
                <div class="right">
                    <b>Rotterdam Speedboat</b>
                    <span>Strandweg 23</span>
                    <span>2761 DM Zevenhuizen</span>
                    <span>info@rotterdamspeedboat.nl</span>
                    <span>010 420 1115</span>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Reservering',

    metaInfo() {
        return { 
            title: "Rotterdam Speedboat",
            meta: [
                { name: 'description', content:  '' },
                { property: 'og:title', content: "Rotterdam Speedboat" },
                { property: 'og:site_name', content: 'rotterdamspeedboat' },
                { property: 'og:type', content: 'website' },
                { name: 'robots', content: 'index,follow' }
            ]
        }
    }
}
</script>

<style>

</style>