import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueGtag from "vue-gtag";
import VCalendar from 'v-calendar'
import VueMeta from 'vue-meta'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VCalendar)
Vue.use(VueMeta)
Vue.use(VueToast);
Vue.use(VueGtag, {
  config: { id: "G-PKBZD67Y2W" }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
